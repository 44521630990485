import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Академія DriveMaster
			</title>
			<meta name={"description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:title"} content={"Академія DriveMaster"} />
			<meta property={"og:description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link5" />
			<Override slot="link4" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="box3" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					Наші курси призначені для учнів усіх рівнів, від початківців до тих, хто хоче освіжити свої навички водіння. Приєднуйтесь до нас, щоб розпочати вашу подорож до опанування мистецтва водіння.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="block"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
				flex="1 1 0%"
				flex-wrap="wrap-reverse"
			/>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Інструктори-експерти
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Наша команда сертифікованих професіоналів — це не лише досвідчені водії, але й чудові викладачі.
				</Text>
			</Box>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Інструктори-експерти
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Ми пропонуємо різноманітні години та дати відповідно до вашого напруженого графіка, гарантуючи, що навчання водінню буде для вас зручним.
				</Text>
			</Box>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Сучасний автопарк
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Наш автопарк регулярно оновлюється та підтримується за найвищими стандартами безпеки та комфорту.
				</Text>
			</Box>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline3" letter-spacing="-.06em" color="--dark">
					Конкурентоздатні ціни
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Отримайте найкращу цінність своїх інвестицій у навчання водінню за допомогою наших курсів за конкурентними цінами.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09:20:28.303Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-1.jpg?v=2024-05-27T09%3A20%3A28.303Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Наші комплексні пропозиції курсів
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Схема поля була розроблена таким чином, щоб забезпечити найкращі умови як для змагань, так і для розваг.
						</Text>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Керівництво громадою
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Ми пишем тим, що володіємо атмосферним співтовариством, де виси члени кюсут себе бажаними та цінніми.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/services"
							padding="0 0 0 0"
							background="0"
							color="--red"
							type="link"
							text-transform="uppercase"
						>
							ДІЗНАТИСЯ БІЛЬШЕ&gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-17">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-grid-template-columns="repeat(2, 1fr)"
				grid-auto-flow="row"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="10px 10px"
				grid-template-rows="auto"
				lg-grid-template-rows="repeat(4, 117px)"
				lg-align-self="center"
				lg-grid-gap="36px"
				width="100%"
				display="grid"
				padding="16px 0px 16px 0px"
				lg-grid-template-columns="repeat(4, 1fr)"
			>
				<Box
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					justify-content="space-between"
					lg-padding="16px 16px 16px 16px"
					display="flex"
					flex-direction="row"
					lg-width="100%"
					padding="16px 0 16px 0"
					lg-order="1"
					width="100%"
					align-items="flex-start"
					sm-margin="0px 0px 0px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--lead"
							lg-text-align="center"
							md-text-align="left"
							width="100%"
						>
							Наша академія побудована на основі безпеки, професіоналізму та всебічної підготовки водіїв. Маючи багаторічний досвід викладання та глибоке розуміння протоколів безпеки дорожнього руху, наші інструктори віддані справі проведення ефективного та цікавого навчання. У DriveMaster ми використовуємо новітні методи навчання та найсучасніші транспортні засоби, щоб забезпечити вам найкращий досвід навчання.
						</Text>
					</Box>
				</Box>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					display="flex"
					align-items="center"
					justify-content="center"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 0px 0px"
				>
					<Image
						object-fit="cover"
						position="relative"
						width="100%"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						src="https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09:20:28.291Z"
						display="block"
						top="auto"
						srcSet="https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-1.jpg?v=2024-05-27T09%3A20%3A28.291Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					display="flex"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					lg-justify-content="center"
					hover-transform="translateY(-10px)"
					padding="0px 0px 0px 0px"
					lg-align-items="center"
					lg-display="flex"
					align-items="center"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					justify-content="center"
				>
					<Image
						bottom="0px"
						width="100%"
						left={0}
						position="relative"
						display="block"
						top="auto"
						right={0}
						min-height="100%"
						height="112px"
						src="https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1562877961-efcec8439f9d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="0px 0px 0px 0px"
					justify-content="center"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					display="flex"
					position="relative"
					hover-transform="translateY(-10px)"
					lg-display="flex"
					align-items="center"
				>
					<Image
						width="100%"
						top="auto"
						right={0}
						src="https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09:20:28.289Z"
						position="relative"
						display="block"
						left={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/2-2.jpg?v=2024-05-27T09%3A20%3A28.289Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});